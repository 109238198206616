<template>
  <div>
    <div class="header">
      <div
        @click="backToMenu"
        class="btn-img">
        <img src="@/assets/icon-menu.png"/>
      </div>
    </div>
    <div class="page-title">
      Result
    </div>
    <div class="menu-row">
      <div
        @click="toResultsPage('lesson')">
        <img src="@/assets/menu-lessons.png"/>
      </div>
      <div
        @click="toResultsPage('trial')">
        <img src="@/assets/menu-trial.png"/>
      </div>
    </div>
    <div 
      @click="toReportPage()"
      class="second-row mt-4">
      <img src="@/assets/menu-report.png"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonResultType',
  data() {
    return {
      selectedType: null,
      selectedResultId: null,
    }
  },
  methods: {
    backToMenu() {
      this.$router.push('/menu')
    },
    toResultsPage(type) {
      this.$router.push(`steps?redirectTo=lessonResults?type=${type}`)
    },
    toReportPage() {
      this.$router.push('/report')
    },
  }
}
</script>

<style lang="scss" scoped>
.lessons {
  margin-top: 50px;
  .lesson-row {
    display: flex;
    .lesson {
      flex: 1;
      background: white;
      border-radius: 50px;
      margin: 40px 10px;
      padding: 30px 5px;
      cursor: pointer;
      border: 1px solid gray;
    }
  }
}
.minus-margin {
  margin-top: -40px;
}
.search-calendar {
  margin: auto;
  margin-top: 15px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.results {
  margin: auto;
  margin-top: 20px;
  width: 80%;
  overflow-y: scroll;
  height: 45vh;
  background: white;
  border-radius: 5px;
  padding: 5px 0px;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  text-decoration: underline;
  color: #565258;
  table {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border-collapse: collapse;
    td {
      border-bottom: 1px solid rgb(192, 186, 186);
      line-height: 250%;
    }
    tr:hover, .selected {
      background: rgb(243, 233, 233);
      cursor: pointer;
    }
  }
}
.menu-row {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
  img {
    width: 300px;
    cursor: pointer;
  }
}
</style>

